import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import BrandTwo from "../elements/BrandTwo";
import PortfolioList from "../elements/PortfolioList";
import HeaderTwo from "../elements/HeaderTwo";
import FooterTwo from "../elements/FooterTwo";
import CallAction from "../elements/CallAction";
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Helmet from "../elements/Helmet";

const SlideList = [
    {
        textPosition: "text-center",
        bgImage: "bg_image--1",
        category: "",
        title: "Pushing the boundaries of display",
        description: "Museum technical services since 1989",
        buttonText: "Contact Us",
        buttonLink: "/contact",
        buttonText2: "Testimonials",
        buttonLink2: "/about#testimonials",
    },
];

class Home extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true });
    }
    render() {
        var mountMakingPoints = [
            "Experience with a wide variety of artefacts",
            "Creating bespoke mounts with appropriate materials",
            "Innovative, experimental and technologically refined mounts",
        ];
        var displayInstallationPoints = [
            "All shapes and sizes of displays",
            "Complex objects are a speciality",
            "Working with you to maximise expressive potential",
        ];
        var designPoints = [
            "Craft and Design background", 
            "Materials engineering knowledge",
            "Skilled in technical crafting"
        ];

        return (
            <Fragment>
                <Helmet pageTitle="Home" />

                {/* Start Header Area  */}
                <HeaderTwo />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div
                            className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                            key={index}
                            data-black-overlay="6"
                        >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ""}
                                            {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ""}
                                            {value.description ? (
                                                <p className="description">{value.description}</p>
                                            ) : (
                                                ""
                                            )}
                                            {value.buttonText ? (
                                                <div className="slide-btn">
                                                    <a
                                                        className="rn-button-style--2 btn-solid"
                                                        href={`${value.buttonLink}`}
                                                    >
                                                        {value.buttonText}
                                                    </a>
                                                    <a
                                                        className="rn-button-style--2 btn-solid"
                                                        href={`${value.buttonLink2}`}
                                                    >
                                                        {value.buttonText2}
                                                    </a>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}

                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--5 ptb--60">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                                <h2>Our Clients & Partners</h2>
                                <div className="col-lg-12">
                                    <BrandTwo />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Mount-Making Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Mount-Making</h2>
                                        <p className="description">
                                        As museum professionals, our mission and yours is to protect the common heritage of mankind 
                                        and to provide an environment in which it can be enjoyed and studied. We understand that 
                                        conservation is the most important tenet of mount making and we combine this with refined 
                                        aesthetics and intelligent design to create the best possible displays for your museum or gallery.
                                        </p>
                                    </div>
                                    <div className="mt--30">
                                        <ul className="list-style--1">
                                            {mountMakingPoints.map((name, index) => {
                                                return (
                                                    <li key={index}>
                                                        <FiCheck /> {name}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                    <div className="about-button mt--50">
                                        <a className="rn-button-style--2 btn-solid" href="/mount-making">
                                            More about mounts
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img
                                        className="w-75"
                                        src="assets/images/services/mountmaking_christ2.png"
                                        alt="Christ figurine in mount"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Mount-Making Area  */}

                {/* Start Display Installation Area  */}
                <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img
                                        className="w-100"
                                        src="/assets/images/roman_display.jpg"
                                        alt="Roman Military Display"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Display Installation</h2>
                                        <p className="description">
                                        Rutherford & Wheeler are more than just mount-makers. We have a long history 
                                        of working with our clients to install their displays. We find that the intimate 
                                        process of creating mounts lends itself well to knowing how to display them too 
                                        and this end-to-end service is what makes it so easy for clients to work with us. 
                                        </p>
                                    </div>
                                    <div className="mt--30">
                                        <ul className="list-style--1">
                                            {displayInstallationPoints.map((name, index) => {
                                                return (
                                                    <li key={index}>
                                                        <FiCheck /> {name}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                    <div className="about-button mt--50">
                                        <a className="rn-button-style--2 btn-solid" href="/display-installation">
                                            More about display
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Display Installation Area  */}

                {/* Start Design Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Design</h2>
                                        <p className="description">
                                        Every curator wants their audience to be amazed by the beauty of the objects on display, 
                                        to draw in and engage the viewer. We have a passion for design and love putting our artistic 
                                        talents to good use. We can advise on the design of any of your displays, having seen and 
                                        worked on hundreds ourselves, 
                                        </p>
                                    </div>
                                    <div className="mt--30">
                                        <ul className="list-style--1">
                                            {designPoints.map((name, index) => {
                                                return (
                                                    <li key={index}>
                                                        <FiCheck /> {name}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                    <div className="about-button mt--50">
                                        <a className="rn-button-style--2 btn-solid" href="/design">
                                            Our design skills
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img
                                        className="w-100"
                                        src="/assets/images/medical_display.jpg"
                                        alt="Medical Display"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Design Area  */}

                {/* Start Case Study Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Case Studies</h2>
                                        <p>
                                            Want to dive deeper to see what results we can bring to your projects? Have
                                            a read about three of our finest projects below - we think they really
                                            showcase some of our best work.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList
                                    styevariation="text-left mt--40"
                                    column="col-lg-4 col-md-6 col-sm-6 col-12"
                                    item="3"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Case Study Area */}

                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        );
    }
}
export default Home;
