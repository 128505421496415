import React, { Component } from "react";
import PageHelmet from "../../elements/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../../elements/HeaderTwo";
import FooterTwo from "../../elements/FooterTwo";

class CaseStudyDuxford extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle="Airborne Assault Exhibition" />
                <HeaderTwo headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Airborne Assault Exhibition</h2>
                                    <p>Imperial War Museum, Duxford</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <blockquote className="rn-blog-quote">
                                            "A jeep and its trailer, a motorcycle and anti-tank guns defy gravity,
                                            hanging just above the floor from steel cables" - Javier Pes, Museums Journal
                                        </blockquote>
                                        <p>
                                            Many have heard of the Paras, the Parachute Regiment of the British Army,
                                            but few can imagine what it is like to jump out of a plane over enemy
                                            territory with only your gun, your gear, and your brothers-in-arms.
                                        </p>
                                        <p>
                                            We were contracted by the designers, RWDP, to take responsibility for
                                            mount-making and display installation. We worked together to create the
                                            Airborne Assault Exhibition to convey that intense feeling to the general
                                            public.
                                        </p>
                                        <p>
                                            We set up our mobile workshop at Browning Barracks in Aldershot to begin the
                                            three weeks of initial mount-making before moving to the museum site in
                                            Duxford for an intensive two weeks of installation to complete the project.
                                        </p>
                                        <div className="thumbnail">
                                            <div><img
                                                src="/assets/images/case_studies/duxfordwide.jpg"
                                                alt="Museum wider angle"
                                            /></div>
                                            <div><span>A wider shot of the whole museum</span></div>
                                        </div>
                                        <h4 className="title mt--40">Challenging Cables</h4>
                                        <p>
                                            A key design point of the exhibition was visually busy walls of objects
                                            floating in mid-air. This was achieved by employing thin steel cables
                                            anchored from floor to ceiling.
                                        </p>
                                        <p>
                                            Dangerously high tensions can result if this technique is improperly used.
                                            It requires a watertight knowledge of the structural integrity of the
                                            surrounding materials - it is a rarely used technique in object installation
                                            for this reason.
                                        </p>
                                        <p>
                                            We managed to overcome the challenges and produced a display with an
                                            exciting feeling of weightlessness, making the audience feel as though they
                                            are taking part in the parachute jump themselves!
                                        </p>
                                        <div className="thumbnail">
                                            <div><img
                                                src="/assets/images/case_studies/duxfordbike.jpg"
                                                alt="Motorbike hanging"
                                            /></div>
                                            <div><span>Motorbike hanging via steel cables</span></div>
                                        </div>
                                        <h4 className="title mt--40">Structural Tensions</h4>
                                        <p>
                                            A huge display case full of the Para’s weapons and war trophies is the
                                            centrepiece of the exhibition. The audience is invited to wander around it
                                            to admire the display of war materiel from every angle.
                                        </p>
                                        <p>
                                            We identified issues with the strength of the supplied display cases during
                                            the project. The tension across the cables with the weapons attached was
                                            going to be too great and could have lead to the total structural failure of
                                            the whole case!
                                        </p>
                                        <p>
                                            We used our extensive materials expertise to go above and beyond the
                                            standard remit expected of the average mount-maker - we presented improved
                                            specifications that allowed the display case to be upgraded and prevented
                                            any future issues with the display.
                                        </p>
                                        <div className="thumbnail">
                                            <div><img
                                                src="/assets/images/case_studies/duxfordweapons.jpg"
                                                alt="Weapons display case"
                                            /></div>
                                            <div><span>The finished weapons display case</span></div>
                                        </div>
                                        <h4 className="title mt--40">Finishing Touches</h4>
                                        <p>
                                            The exhibition was opened by the colonel-in-chief of the parachute regiment,
                                            Charles, Prince of Wales. Colonel-in-Chief is a purely ceremonial role found
                                            in many military regiments around the world.
                                        </p>
                                        <p>
                                            This display is still an unmatched example of small arms display to this day
                                            and imitations can be seen in other museums, however we believe that none
                                            have been executed as finely.{" "}
                                        </p>
                                        <p>
                                            Pride of place in the weapons case is a gold-plated Dragunov sniper rifle,
                                            seized when the Paras raided Saddam Hussein’s palace in Baghdad during the
                                            Iraq War.
                                        </p>
                                        <div className="thumbnail">
                                            <div><img src="/assets/images/case_studies/duxfordgold.jpg" alt="Saddam Hussein's gold-plated sniper rifle" /></div>
                                            <div><span>Saddam Hussein's gold-plated sniper rifle</span></div>
                                        </div>
                                        <div className="content">
                                            <h4 className="title mt--40">Similar Projects</h4>
                                            <p>
                                                The Airborne Assault Exhibition at the Imperial War Museum Duxford was
                                                just one of a long line of military and regimental collections that we
                                                have completed for a variety of museum and designer clients including:
                                            </p>
                                            <ul className="list-style">
                                                <li>The D-Day Museum, Portsmouth</li>
                                                <li>The National Civil War Centre, Newark</li>
                                                <li>The Border Regiment Museum, Carlisle</li>
                                                <li>The Black Watch Regiment Museum, Perth</li>
                                                <li>The Gloucestershire Regiment Museum, Gloucester</li>
                                                <li>The Green Howards Regiment Museum, Richmond</li>
                                                <li>Fusiliers Museum of Northumberland, Alnwick Castle</li>
                                                <li>The Yorkshire Regiment Museum, York</li>
                                            </ul>
                                            <h4 className="title mt--40">Further Links</h4>
                                            <ul className="list-style">
                                                <li>
                                                    <a href="http://www.rwdp.co.uk/page27/page27.html">
                                                        RWDP Designers
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.paradata.org.uk/article/airborne-assault-museum-iwm-duxford">
                                                        Airborne Assault Museum Official Website
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.tripadvisor.co.uk/Attraction_Review-g1076917-d12872318-Reviews-Airborne_Assault-Duxford_Cambridgeshire_England.html">
                                                        Airborne Assault Museum on TripAdvisor
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />
            </React.Fragment>
        );
    }
}
export default CaseStudyDuxford;
