import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/english-heritage.png" alt="English Heritage"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/st-andrews-logo.png" alt="University of St Andrews"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/iwm-logo.png" alt="Imperial War Museums"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/national-trust-logo.png" alt="National Trust"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/national-army-museum.png" alt="National Army Museum"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/rcs-england-logo.png" alt="Royal College of Surgeons England"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/royal-armouries.png" alt="Royal Armouries"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/british-museum-logo.png" alt="The British Museum"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/civil-war-logo.png" alt="National Civil War Centre"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/durham-university-logo.png" alt="Durham University"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/scotlands-museums.png" alt="National Museums Scotland"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/hunterian.png" alt="The Hunterian"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/va-logo.png" alt="The V&A"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/lincoln.png" alt="Lincoln Cathedral"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/rcsed.png" alt="Royal College of Surgeons Edinburgh"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;