// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Home layout
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import ServiceMountMaking from './pages/services/ServiceMountMaking';
import ServiceInstallation from './pages/services/ServiceInstallation';
import ServiceDesign from './pages/services/ServiceDesign';
import CaseStudyDuxford from './pages/case_studies/CaseStudyDuxford';
import CaseStudyBolton from './pages/case_studies/CaseStudyBolton';
import CaseStudyWakefield from './pages/case_studies/CaseStudyWakefield';
import Error404 from './pages/error404';

import { Redirect, BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/mount-making`} component={ServiceMountMaking}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/display-installation`} component={ServiceInstallation}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/design`} component={ServiceDesign}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/duxford-case-study`} component={CaseStudyDuxford}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/bolton-case-study`} component={CaseStudyBolton}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/wakefield-case-study`} component={CaseStudyWakefield}/>

                    <Route exact path={`${process.env.PUBLIC_URL}/404`} component={Error404}/>
                    <Redirect to="/404" />
                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();