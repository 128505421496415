import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>
                                            I have invariably been delighted with the work delivered by the outstanding R&W team, 
                                            and my colleagues and I have been fortunate to have the chance to benefit not only from 
                                            their exceptional understanding of our collections but also from their creative, sympathetic 
                                            and nuanced approach to the process of display mount design and manufacture.

                                            In my experience, no display challenge is beyond the ingenuity of the R&W team, 
                                            whose outstanding craft and aesthetic skills have supported us in the delivery of both innovative 
                                            galleries and major international touring exhibitions.
                                            </p>
                                    </div>
                                    <div className="author-info">
                                    <h6><span>Dr Craig Barclay</span> - Head of Museums, Galleries & Exhibitions, Durham University</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>
                                            These guys add the sparkle to any museum display and are an absolute pleasure to work with!

                                            I have worked with the team at Rutherford & Wheeler for several years, both at Wakefield and 
                                            later Barnsley Museums. They are a very talented creative and skilful. Their mounts are beautiful 
                                            and they always find display solutions that work for galleries of every size and budget. I know 
                                            that when I work with them our displays will be completed to the top level of professionalism and 
                                            finish. Plus having worked the length and breadth of the UK, their insight and experience is invaluable. 
                                            We at Barnsley are proud that the team worked on the redisplay of all our social history galleries in 
                                            Experience Barnsley, all of which looks really great.
                                            </p>
                                    </div>
                                    <div className="author-info">
                                    <h6><span>Steven Skelley</span> - Community Heritage Curator, Barnsley Museums</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>The Royal College of Surgeons of Edinburgh’s Lister Project has been recognised as the best permanent 
                                            exhibition in the UK at the prestigious Museums + Heritage Awards for Excellence 2016...
                                            
                                            President of the RCSEd Mr Michael Lavelle-Jones said: “The redeveloped museum has provided a fantastic platform 
                                            to showcase our College's important role in the development of surgery over 500 years. We are absolutely delighted 
                                            with the recognition that this award brings..."

                                            RCSEd’s Director of Heritage Chris Henry said: “After three years of intense effort, these awards are recognition of 
                                            the efforts of all the staff who worked on the project. They propel Surgeons' Hall into the top rank of museums in 
                                            the UK and I'd like to thank everyone who was involved in making our museum such a success.
                                            </p>
                                    </div>
                                    <div className="author-info">
                                        <h6>Museums + Heritage Awards for Excellence 2016 Ceremony</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>The story of the rebirth of this truly special museum nestled on the remote north-westerly coast of Scotland 
                                            captivated the judges... The redisplay of the museum’s collection which encapsulates 
                                            the history, culture, beauty and character of Gairloch and its new home have reaminated the village’s pride 
                                            in its heritage, created a buzzing new community hub, and produced a sustainable cultural landmark for 
                                            generations of visitors to enjoy.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Art Fund</span> - Museum of the Year Award 2020 citation for Gairloch Museum, Scotland</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/testimonial/durham-shield.jpg" alt="Durham University Shield"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/testimonial/barnsley-tower.png" alt="Barnsley Tower"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/testimonial/museums-awards.png" alt="Museums and Heritage Awards"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/testimonial/artfund.png" alt="Art Fund"/>
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>
                            <div className="thumb-under-text">Click the icons for more!</div>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;