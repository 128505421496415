import React, { Component } from "react";
import PageHelmet from "../elements/Helmet";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import CallAction from "../elements/CallAction";
import HeaderTwo from "../elements/HeaderTwo";
import FooterTwo from "../elements/FooterTwo";
import { Link } from 'react-router-dom'

function getYearsExperience() {
    return (2013 - new Date().getFullYear()) * -1;
}

class About extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle="About Us" />
                <HeaderTwo headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Title Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--11" data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">About Us</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Title Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-6">
                                    <div className="thumbnail">
                                        <img
                                            className="w-100"
                                            src="/assets/images/aboutus/wheelersforwebby.jpg"
                                            alt="About Images"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <p className="description">
                                            Hello! At Rutherford & Wheeler it gives us incredible satisfaction to share with you our genuine love of the craftsmanship 
                                            that goes into great museums. Creating beautiful displays is what gets us up in the morning and has kept us coming back for 
                                            more since 1989. We are a family-run business, which allows us to provide a highly personalised service to you. Design runs 
                                            through our family’s generations, from sculpture to woodworking, architecture, photography and music - our wide exposure to 
                                            different disciplines allows us to take the best of what works and discard the rest. We are sought after for our high-quality, 
                                            innovative mount-making solutions as well as the wide-ranging technical knowledge we can provide about the museum and gallery 
                                            environment, having completed hundreds of projects over the years. Scroll down to see testimonials from our clients, and then 
                                            <Link to={"./contact"}> contact us</Link> today to find out what we can do for you!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-6">
                                    <div className="row mt--30">
                                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Jasper Wheeler</h3>
                                                <p>
                                                    Jasper has been working with R&W since 2020 and is a capable mount maker 
                                                    in his own right with a passion for excellence and fine detail.
                                                </p>
                                            </div>
                                        </div> 
                                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Lee Wheeler</h3>
                                                <p>
                                                    Lee has been in the museum technical services sector since 1989.
                                                    He started out as a technician in the British Museum before
                                                    moving into the private sector, establishing Rutherford &
                                                    Wheeler in 1992.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">James Wheeler</h3>
                                                <p>
                                                    James joined the company in 2013 and has now taken on a
                                                    full technician role as well as responsibility for
                                                    administration of the company.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--5 ptb--120" id="testimonials">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--1 ptb--60">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                                <h2>Our Clients & Partners</h2>
                                <div className="col-lg-12">
                                    <BrandTwo />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />
            </React.Fragment>
        );
    }
}
export default About;
