import React, { Component } from "react";

class ContactTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rnName: "",
            rnEmail: "",
            rnSource: "",
            rnMessage: ""
        };
    }
    render() {
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Contact Us Now</h2>
                                <p className="description"></p>
                            </div>
                            <div className="form-wrapper">
                                <form action="https://app.99inbound.com/api/e/L-xE7D79" method="POST" target="_blank">


                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e) => {
                                                this.setState({ rnName: e.target.value });
                                            }}
                                            placeholder="Your name*"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e) => {
                                                this.setState({ rnEmail: e.target.value });
                                            }}
                                            placeholder="Your email*"
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="source"
                                            id="item03"
                                            value={this.state.rnSource}
                                            onChange={(e) => {
                                                this.setState({ rnSource: e.target.value });
                                            }}
                                            placeholder="Where did you hear about us? (Optional)"
                                        />
                                    </label>

                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e) => {
                                                this.setState({ rnMessage: e.target.value });
                                            }}
                                            placeholder="Please enter your message*"
                                        />
                                    </label>

                                    <label id="nocheckme" htmlFor="item00">
                                        <input
                                        id="item00"
                                        type="checkbox" 
                                        name="wireless_grey_houndstooth_mousepad" 
                                        tabIndex="-1" 
                                        autoComplete="no"
                                        />
                                    </label>

                                    <button
                                        className="rn-button-style--2 btn-solid"
                                        type="submit"
                                        value="submit"
                                        name="submit"
                                        id="mc-embedded-subscribe"
                                    >
                                        Send Message
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/military_man_armour_display.jpg" alt="Military man arms display" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ContactTwo;
