import React, { Component } from "react";
import PageHelmet from "../elements/Helmet";
import { FiMail, FiMapPin } from "react-icons/fi";
import ContactTwo from "../elements/ContactTwo";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../elements/HeaderTwo";
import FooterTwo from "../elements/FooterTwo";

class Contact extends Component {
        
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <React.Fragment>
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle="Contact Us" />
                {/* End Pagehelmet  */}

                <HeaderTwo headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--3" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Contact Us</h2>
                                    <p>
                                        To have a chat about what we can do for you, please contact us below and one of
                                        our team will be in touch as soon as possible
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            {/* Start Email  */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email</h4>
                                        <p>
                                            <a href="mailto:contact@rutherfordandwheeler.com">
                                                contact@ <br/>rutherfordandwheeler.com
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* End Email  */}

                            {/* Start Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Location</h4>
                                        <p>
                                            10 Lovell Park Close, Leeds, <br />
                                            West Yorkshire, LS7 1DG
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* End Address  */}
                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Form Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div>
                {/* End Contact Form Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />
            </React.Fragment>
        );
    }
}
export default Contact;
