import React, { Component } from "react";
import PageHelmet from "../../elements/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../../elements/HeaderTwo";
import FooterTwo from "../../elements/FooterTwo";

class ServiceDesign extends Component {
    render() {
        return (
            <React.Fragment>
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle="Design" />
                {/* End Pagehelmet  */}

                <HeaderTwo headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Our Design Skills</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--80 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center justify-content-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                    Every curator wants their audience to be amazed by the beauty of the objects on display, 
                                        to draw in and engage the viewer. We have a passion for design and love putting our artistic 
                                        talents to good use. We can advise on the design of any of your displays, having seen and 
                                        worked on hundreds ourselves, 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img
                                                        className="w-100"
                                                        src="/assets/images/design/design_glass.jpg"
                                                        alt="Stack of measuring pots"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4>Craft and Design background</h4>
                                                    <p>
                                                    Our personnel have been steeped in the creative world for generations, and our roots 
                                                    lie in craft, sculpture and design. This allows us to bring a greater breadth of creative 
                                                    experience to our work.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4>Materials engineering knowledge</h4>
                                                    <p>
                                                    We have a keen interest in keeping our style contemporary, using cutting-edge 
                                                    materials and modern technical processes. Combining modern physical processes 
                                                    with timeless design ideas, our displays stay relevant throughout the long 
                                                    timeframes of permanent exhibitions.
                                                    </p>
                                                    <p>
                                                    We can draft designs manually using traditional 
                                                    drawing techniques or we can 2D/3D render using CAD computer software.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img
                                                        className="w-100"
                                                        src="/assets/images/design/design_table.jpg"
                                                        alt="Table Arrangement"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />
            </React.Fragment>
        );
    }
}
export default ServiceDesign;
