import React, { Component } from "react";
import PageHelmet from "../../elements/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../../elements/HeaderTwo";
import FooterTwo from "../../elements/FooterTwo";
import { Link } from 'react-router-dom'


class ServiceInstallation extends Component {
    render() {
        return (
            <React.Fragment>
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle="Display Installation" />
                {/* End Pagehelmet  */}

                <HeaderTwo headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--9" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Display Installation</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--80 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center justify-content-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                    Rutherford & Wheeler are more than just mount-makers. We have a long history of working 
                                                    with our clients to install their displays. We find that the intimate process of 
                                                    creating mounts lends itself well to knowing how to display them too and this 
                                                    end-to-end service is what makes it so easy for clients to work with us. 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img
                                                        className="w-100"
                                                        src="assets/images/display_installation/display_inst_shell.jpg"
                                                        alt="WWII shell on display"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4>All shapes and sizes of displays</h4>
                                                    <p>
                                                    Display Installation is a large part of Rutherford & Wheeler’s service. We have experience 
                                                    with display cases small and large, hanging from high ceilings, walls, floors, the 
                                                    great outdoors - we work everywhere.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4>Complex objects are a speciality</h4>
                                                    <p>
                                                    We have plenty of experience installing complex objects. We understand that 
                                                    each of your objects is different, and ancient Roman textile fragments require 
                                                    a totally different approach to a WWII naval mine. One of the more exotic 
                                                    objects we have displayed is a hanging dinosaur skeleton which you can read about 
                                                    <Link to={"./bolton-case-study"}> here</Link>.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img
                                                        className="w-100"
                                                        src="assets/images/case_studies/dinosaurscaffolding.png"
                                                        alt="Dinosaur being raised with scaffolding"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img
                                                        className="w-100"
                                                        src="assets/images/display_installation/display_inst_moto_parts.jpg"
                                                        alt="Motorcycle parts"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4>Working with you to maximise expressive potential</h4>
                                                    <p>
                                                    We understand the important balance between design and use of space. Our 
                                                    goal is to present the objects to the audience in a way that maximises their 
                                                    expressive potential. To achieve this, we work hand in hand with you to create 
                                                    the feel you desire for your exhibition.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />
            </React.Fragment>
        );
    }
}
export default ServiceInstallation;
