import React, { Component } from "react";
import PageHelmet from "../../elements/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../../elements/HeaderTwo";
import FooterTwo from "../../elements/FooterTwo";

class CaseStudyWakefield extends Component {
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle="Working With Wakefield Museum" />
                <HeaderTwo headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Working With Wakefield Museum</h2>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <blockquote className="rn-blog-quote">
                                            “It's been a great privilege to open this spectacular new museum. It's a
                                            beautiful design, is beautifully displayed and is full of interesting
                                            things” - Sir David Attenborough
                                        </blockquote>
                                        <p>
                                            We have a long and varied history with Wakefield Museum, having done many
                                            projects with them across multiple museums in the local area. They are a
                                            long-standing satisfied client whom we greatly enjoy working with! Here is a
                                            selection of our projects:
                                        </p>
                                        <div className="thumbnail">
                                            <div>
                                                <img
                                                    src="/assets/images/case_studies/wakefieldring.jpg"
                                                    alt="Lit-up ring display"
                                                />
                                            </div>
                                            <div>
                                                <span>A well-lit display of a single gold ring</span>
                                            </div>
                                        </div>
                                        <h4 className="title mt--40">Wakefield One Museum</h4>
                                        <p>
                                            Our relationship with Wakefield museum started in 2012 when we were
                                            contracted by RS displays to do the mount making and installation of the
                                            exhibition space on the ground floor of Wakefield One, the new council
                                            office. The museum had been moved there from their historic building in the
                                            town centre and the highly capable team at RS displays were doing the space
                                            design and exhibition fit out.
                                        </p>
                                        <p>
                                            The collection was highly varied and demonstrated a wide ranging history of
                                            the Wakefield area from ancient times to modern with various aspects of
                                            social, natural and military history explored throughout. We worked on
                                            around 300 objects throughout 14 display cases over 6 weeks. This project
                                            laid a strong foundation and working relationship with the Wakefield team.
                                        </p>
                                        <p>
                                            The exhibition was opened by Sir David Attenborough who was also a personal
                                            fan of Wakefield’s Victorian-era naturalist, Charles Waterton, whose story
                                            and innovations were explored in the exhibition.
                                        </p>
                                        <div className="thumbnail">
                                            <div>
                                                <img src="/assets/images/design/design_stack.jpg" alt="Stack of pots" />
                                            </div>
                                            <div>
                                                <span>Creatively stacked pots at Wakefield One Museum</span>
                                            </div>
                                        </div>
                                        <h4 className="title mt--40">Thousand-year-old Log Boat</h4>
                                        <p>
                                            Another challenging project we completed for the team at Wakefield museum
                                            was the redisplay of a Viking-era ‘log boat’, so-called because it is made
                                            from a single hollowed out tree trunk. This boat was discovered in 1838 in
                                            the River Calder when the Stanley Ferry aqueduct was being built. It is the
                                            only one of its type in the UK and dates from around the year 1000.
                                        </p>
                                        <p>
                                            Much of the artefact had rotted away, and we were left with fragments, so
                                            the project required us to reconstruct its original shape through creative
                                            mount-making. We chose to do this by fabricating a close fitting metal frame
                                            which would hold all of the fragments in their relative positions,
                                            recreating the boats original hull shape. The final form of the
                                            reconstructed boat went on display in a specially made case in Wakefield
                                            Library in 2014. The display was very well received by the museum team, the
                                            public and garnered plenty of media attention as can be seen in the article
                                            at the end of this page from the BBC.
                                        </p>
                                        <div className="thumbnail">
                                            <div>
                                                <img
                                                    src="/assets/images/portfolio/portfolio-3.jpg"
                                                    alt="Wakefield Log-Boat"
                                                />
                                            </div>
                                            <div>
                                                <span>The log-boat before mounting</span>
                                            </div>
                                        </div>
                                        <h4 className="title mt--40">Local Lampshades</h4>
                                        <p>
                                            In 2018 the Wakefield team contracted us to display their large collection
                                            of historic lamp shades which had been made by a local firm in the past. We
                                            suggested the idea of welding up metal trees in their large atrium case that
                                            could support multiple lampshades and then actually wire light bulbs into
                                            each shade to really show them off. This worked flawlessly and looked great
                                            when installed.{" "}
                                        </p>
                                        <p>
                                            We were offered another project following on from this work, after the
                                            lampshades were no longer displayed. Unfortunately, the display cases were
                                            missing rubber grommets. These grommets should have been protecting the
                                            lighting wires from the sharp edges of the drilled holes in the steel floor
                                            of the case. Instead, the sharp edges had stripped and cut the wires which
                                            had caused half of the lights to fail - our task was to rewire and replace
                                            the lights.
                                        </p>
                                        <p>
                                            We achieved this with modern LED strip lighting which gave the Wakefield
                                            team two great benefits. One, the case had a much more modern feel as it was
                                            much newer than their dated lensed LED lighting. Second, the new system
                                            could be adjusted via remote control so they had full creative control over
                                            the lighting in the case. This opened up many new display options as the
                                            case itself houses a number of community projects as well as historic
                                            artefact displays.
                                        </p>
                                        <div className="thumbnail">
                                            <div>
                                                <img
                                                    src="/assets/images/case_studies/wakefieldlampshades1.jpg"
                                                    alt="Lampshades display"
                                                />
                                            </div>
                                            <div>
                                                <span>The lampshades display</span>
                                            </div>
                                        </div>
                                        <h4 className="title mt--40">Ancient Egyptian Mummy</h4>
                                        <p>
                                            In 2019 we were tasked with displaying a 3000-year-old ancient Egyptian
                                            mummy. She was a woman named Perenbast, believed to be a temple singer, who
                                            was buried on the west bank of the Nile. The mummy was one of several
                                            objects on loan from other museums, including Manchester Museum and the
                                            British Museum. We took delicate care of this ancient artefact and gave it
                                            pride of place in the centre of the exhibition.
                                        </p>
                                        <div className="thumbnail">
                                            <div>
                                                <img
                                                    src="/assets/images/case_studies/wakefieldperenbast2.jpg"
                                                    alt="Perenbast on her mount"
                                                />
                                            </div>
                                            <div>
                                                <span>Perenbast on her mount</span>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <h4 className="title mt--40">Further Links</h4>
                                            <ul className="list-style">
                                                <li>
                                                    <a href="https://www.leisureopportunities.co.uk/news/Wakefield-One-museum-opened-by-Sir-David-Attenborough/304702">
                                                        Wakefield One opened by Sir David Attenborough news article
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.bbc.com/news/uk-england-leeds-28449793">
                                                        Stanley Ferry Log-Boat BBC news article
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.tripadvisor.co.uk/Attraction_Review-g190729-d4752634-Reviews-Wakefield_Museum-Wakefield_West_Yorkshire_England.html">
                                                        Wakefield Museum on TripAdvisor
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />
            </React.Fragment>
        );
    }
}
export default CaseStudyWakefield;
