import React, { Component } from "react";
import PageHelmet from "../../elements/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../../elements/HeaderTwo";
import FooterTwo from "../../elements/FooterTwo";

class CaseStudyBolton extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle="Dinosaur Suspension Project" />
                <HeaderTwo headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--6" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Dinosaur Suspension Project</h2>
                                    <p>Bolton Museum & Aquarium</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <blockquote className="rn-blog-quote">
                                            “With [this dinosaur]… we expect Bolton Library and Museum to be a hugely
                                            popular attraction not only with locals but also visitors from far and wide”
                                            - Councillor John Byrne, Bolton Council
                                        </blockquote>
                                        <p>
                                            Tuojiangosaurus multispinosus, meaning ‘Tuo river lizard with many spines’,
                                            was a herbivorous dinosaur that lived in what is now China 150 million years
                                            ago. It was 6 metres long, 2 metres tall, and weighed 2.8 tonnes - although
                                            today it weighs significantly less!
                                        </p>
                                        <p>
                                            Our friends at M&G, the museum and gallery moving firm, approached us about
                                            collaborating on this project. We were really excited to get our hands on a
                                            dinosaur skeleton and solve the challenges of hanging it safely from the
                                            atrium of Bolton museum.
                                        </p>
                                        <div className="thumbnail">
                                            <div><img
                                                src="/assets/images/case_studies/dinosaurscale.png"
                                                alt="Dinosaur scale"
                                            /></div>
                                            <div><span>The scale of the dinosaur compared to a human</span></div>
                                        </div>
                                        <h4 className="title mt--40">Safety Concerns</h4>
                                        <p>
                                            The piece was in a somewhat sorry state when we first went to see it. Each
                                            bone was a fibreglass shell made by casting the original bones, filling them
                                            with expanding foam, and mounting them on a metal frame to keep all the
                                            bones in position.
                                        </p>
                                        <p>
                                            Soon to be hanging above the heads of the public, this piece was far from
                                            satisfactory.
                                        </p>
                                        <p>
                                            Re-working the structure was only half the job as we also had to build the
                                            new frame to hang from the ceiling. The suspension method was steel cables
                                            suspended from special anchor points in the ceiling. We constructed a gantry
                                            to hang the skeleton from whilst we worked on it. This would show us
                                            precisely how the frame would act when hanging on display and allowed us to
                                            adjust the balance as we went.
                                        </p>
                                        <p>
                                            We wanted the frame to be as strong as possible so we used heavily-welded
                                            large gauge steel. As much of our standard metalworking is in the form of
                                            small brazed armatures made from fine metal wire, this made an interesting
                                            change, especially considering that the mount is usually meant to be as
                                            discreet as possible! The result is a finished frame that is both very safe
                                            and does not detract from the object’s form.
                                        </p>
                                        <div className="thumbnail">
                                            <div><img
                                                src="/assets/images/case_studies/dinosaurgrinding.jpg"
                                                alt="Dinosaur grinding"
                                            /></div>
                                            <div><span>Creating the new frame</span></div>
                                        </div>
                                        <h4 className="title mt--40">Design Decisions</h4>
                                        <p>
                                            There was something wrong — we realised the skeleton was not anatomically
                                            correct! The bones had been positioned incorrectly so we repositioned the
                                            forearms and shoulder blades lower down the sides of the skeleton as per the
                                            drawings in the scientific literature. We were really pleased we spotted
                                            this as we love our displays to be as accurate as possible.
                                        </p>
                                        <p>
                                            Formerly, the bones had been painted a mid-grey which we felt did not
                                            represent their original colour or the fossils they were cast from. We made
                                            the decision to repaint the bones so they contrasted well against the dark
                                            ceiling of the atrium, making them more visually exciting.
                                        </p>
                                        <div className="thumbnail">
                                            <div><img
                                                src="/assets/images/case_studies/dinosaurparts1.jpg"
                                                alt="Disassembling the skeleton"
                                            /></div>
                                            <div><span>Disassembling the skeleton</span></div>
                                        </div>
                                        <h4 className="title mt--40">Time Constraints</h4>
                                        <p>
                                            We had our work cut out on this project. A project like this could easily
                                            take weeks of planning and execution but the proposal dictated that the
                                            whole thing, including hanging, had to be completed within a working week!
                                            The planning, welding, reworking, and repainting took four days, then we
                                            were ready to hang it.
                                        </p>
                                        <p>
                                            We dismantled the skeleton and mount to move it from the workshop to the
                                            museum. Our colleagues at M&G provided a customised vehicle designed for
                                            moving delicate and high-value objects. It had air-suspension that provided
                                            a nice, smooth journey for the skeleton.
                                        </p>
                                        <div className="thumbnail">
                                            <div><img
                                                src="/assets/images/case_studies/dinosaurframe.jpg"
                                                alt="Dinosaur in finished frame"
                                            /></div>
                                            <div><span>The dinosaur with finished frame hanging from the gantry</span></div>
                                        </div>
                                        <h4 className="title mt--40">Hang Tight</h4>
                                        <p>
                                            At the museum, we made a gantry out of two scissor lifts with wooden beams
                                            laid across them - this was the same type of wooden gantry we had been using
                                            in the workshop. Both lifts were raised at the same time to keep the gantry
                                            level. James from R&W and Matt from M&G were at the top of a scaffold tower
                                            ready to rig the cables when the gantry reached the correct height.
                                        </p>
                                        <p>
                                            When all the cables were fixed we allowed them to take the weight then
                                            checked everything was safe and secure. We unhooked the frame from the
                                            gantry and lifted it clear to be lowered and disassembled
                                        </p>
                                        <div className="thumbnail">
                                            <div><img
                                                src="/assets/images/case_studies/dinosaurscaffolding.png"
                                                alt="Dinosaur being raised with scissor lifts"
                                            /></div>
                                            <div><span>The dinosaur being raised with scissor lifts</span></div>
                                        </div>
                                        <h4 className="title mt--40">Looking Good</h4>
                                        <p>
                                            Here you can see the final look of the suspended dinosaur from the
                                            perspective of a member of the public coming into the atrium. With the giant
                                            skeleton hanging from the ceiling being lit up by the light pouring in
                                            through the windows above it, the final result is a visually impactful sight
                                            to behold.
                                        </p>
                                        <p>
                                            Our client was very satisfied, and there were plenty of positive reviews
                                            from the public who were pleased to see their beloved dinosaur back back on
                                            display, better than ever before.
                                        </p>
                                        <p>
                                            Bolton Museums held a naming competition to decide on a name for the
                                            skeleton. Over 400 entries were submitted, with the judges finally settling
                                            on… Jango the Tuojiangosaurus!
                                        </p>
                                        <div className="thumbnail">
                                            <div><img src="/assets/images/case_studies/dinosaurhanging.jpg" alt="Finished dinosaur hanging from the atrium ceiling" /></div>
                                            <div><span>The finished dinosaur hanging from the atrium ceiling</span></div>
                                        </div>
                                        <div className="content">
                                            <h4 className="title mt--40">Further Links</h4>
                                            <ul className="list-style">
                                                <li>
                                                    <a href="https://www.museumtransport.co.uk">M&G Museum Transport</a>
                                                </li>
                                                <li>
                                                    <a href="https://www.tripadvisor.com/Attraction_Review-g187053-d212234-Reviews-Bolton_Library_and_Museum_Services-Bolton_Greater_Manchester_England.html">
                                                        Bolton Museum on TripAdvisor
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.theboltonnews.co.uk/news/16413600.dinosaur-naming-competition-winners-meet-bolton-museums-monster-resident-jango-the-tuojiangosaurus/">
                                                        Dinosaur Naming Competition
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />
            </React.Fragment>
        );
    }
}
export default CaseStudyBolton;
