import React, { Component } from "react";
import PageHelmet from "../../elements/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../../elements/HeaderTwo";
import FooterTwo from "../../elements/FooterTwo";

class ServiceMountMaking extends Component {
    render() {
        return (
            <React.Fragment>
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle="Mount-Making" />
                {/* End Pagehelmet  */}

                <HeaderTwo headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--8" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Mount-Making</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--80 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center justify-content-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>
                                                    As museum professionals, our mission and yours is to protect the common heritage of mankind 
                                        and to provide an environment in which it can be enjoyed and studied. We understand that 
                                        conservation is the most important tenet of mount making and we combine this with refined 
                                        aesthetics and intelligent design to create the best possible displays for your museum or gallery.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img
                                                        className="w-100"
                                                        src="assets/images/mount_making/mountmaking_tusk.jpg"
                                                        alt="Mounted tusk"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4>Experience with a wide variety of artefacts</h4>
                                                    <p>
                                                    Over the years we have gathered experience in every category of artefact from delicate, 
                                                    light sensitive textiles to rare metals each needing specific treatments. We have found 
                                                    ways to keep them safe as well as present them effectively to the viewer. We have proven 
                                                    treatments for all sizes, material and complexities of objects. We can apply this deep 
                                                    knowledge to each and every object, making sure that they are given the treatment they require.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4>Creating bespoke mounts with appropriate materials</h4>
                                                    <p>
                                                    Our mounts balance the best practices of conservation with the optimal way 
                                                    to display objects to the satisfaction of both you and your audiences. 
                                                    We know exactly which materials to use, taking into account the object’s 
                                                    age, material, and the surrounding display conditions.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img
                                                        className="w-100"
                                                        src="assets/images/services/mountmaking_christ2.png"
                                                        alt="Christ figurine in mount"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img
                                                        className="w-100"
                                                        src="assets/images/mount_making/mountmaking_coins.jpg"
                                                        alt="Ancient Roman coins in mount"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4>Innovative, experimental and technologically refined mounts</h4>
                                                    <p>
                                                    Innovation is our passion, and we are constantly experimenting with our existing material set 
                                                    and design of mounts to find new and exciting ways to display your objects in order to give your 
                                                    exhibitions a unique feel.
                                                    </p>
                                                    <p>
                                                    We have incorporated computer-aided design and manufacture (CNC) into our processes. This hi-tech 
                                                    solution allows us to provide you with precision mounts much faster than would be possible with 
                                                    traditional techniques.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />
            </React.Fragment>
        );
    }
}
export default ServiceMountMaking;
